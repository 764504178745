// Audio context singleton
let audioContext: AudioContext | null = null;
let audioBuffers: Map<string, AudioBuffer> = new Map();
let isAudioInitialized = false;

const SOUND_PATHS = {
  success: '/Success_Sound.mp3',
  mistake: '/Mistake_Sound.mp3',
  interaction: '/Interaction_Sound.mp3'
};

// Initialize audio context on user interaction
export function initAudio() {
  if (isAudioInitialized) return;
  
  try {
    audioContext = new AudioContext();
    isAudioInitialized = true;
    
    // Preload all sounds
    Object.entries(SOUND_PATHS).forEach(([key, path]) => {
      loadSound(key, path).catch(err => 
        console.warn(`Failed to preload sound ${key}:`, err)
      );
    });
  } catch (err) {
    console.warn('Failed to initialize audio context:', err);
  }
}

// Load a sound file and cache its buffer
async function loadSound(key: string, path: string): Promise<void> {
  if (!audioContext) return;

  try {
    const response = await fetch(path);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    audioBuffers.set(key, audioBuffer);
  } catch (err) {
    console.warn(`Failed to load sound ${key}:`, err);
  }
}

// Play a sound with error handling and fallback
export async function playSound(key: string) {
  if (!audioContext || audioContext.state === 'closed') {
    initAudio();
  }

  if (audioContext?.state === 'suspended') {
    try {
      await audioContext.resume();
    } catch (err) {
      console.warn('Failed to resume audio context:', err);
      return;
    }
  }

  try {
    if (!audioBuffers.has(key)) {
      const path = SOUND_PATHS[key as keyof typeof SOUND_PATHS];
      if (!path) {
        console.warn(`No path found for sound: ${key}`);
        return;
      }
      await loadSound(key, path);
    }

    const buffer = audioBuffers.get(key);
    if (!buffer || !audioContext) return;

    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(audioContext.destination);
    source.start(0);
  } catch (err) {
    console.warn(`Failed to play sound ${key}:`, err);
    
    // Fallback to Audio element if Web Audio API fails
    try {
      const audio = new Audio(SOUND_PATHS[key as keyof typeof SOUND_PATHS]);
      audio.play().catch(err => console.warn('Fallback audio failed:', err));
    } catch (err) {
      console.warn('Both primary and fallback audio failed');
    }
  }
}