import React, { useState, useEffect } from 'react';
import { X, Medal, Users, Trophy, Flame, Clock } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

interface LeaderboardModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface LeaderboardEntry {
  id: string;
  screenname: string | null;
  avatarUrl: string | null;
  friendInvites: number;
  totalPoints: number;
  loginStreak: number;
  timeSpent: number;
  rank?: number;
}

type LeaderboardTab = 'invites' | 'points' | 'streak' | 'time';
type LeaderboardScope = 'friends' | 'global';

export default function LeaderboardModal({ isOpen, onClose }: LeaderboardModalProps) {
  const { user } = useAuth();
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<LeaderboardTab>('points');
  const [scope, setScope] = useState<LeaderboardScope>('global');

  useEffect(() => {
    const fetchLeaderboard = async () => {
      if (!user) return;
      try {
        setLoading(true);
        
        // Build query for profiles
        let query = supabase
          .from('profiles')
          .select('id, screenname, avatar_url, friend_invites, total_points, activity_streak, time_spent');

        // Apply scope filter
        if (scope === 'friends') {
          // Get friend IDs first
          const { data: friends, error: friendsError } = await supabase
            .from('friends')
            .select('friend_id')
            .eq('user_id', user.id);

          if (friendsError) throw friendsError;

          const friendIds = [...(friends?.map(f => f.friend_id) || []), user.id];
          query = query.in('id', friendIds);
        }

        // Apply ordering based on active tab
        const { data: profiles, error: profilesError } = await query.order(
            activeTab === 'invites' ? 'friend_invites' :
            activeTab === 'points' ? 'total_points' :
            activeTab === 'streak' ? 'activity_streak' :
            'time_spent',
            { ascending: false }
          )
          .limit(10);

        if (profilesError) throw profilesError;

        // Transform and add rankings
        const rankedProfiles = profiles.map((profile, index) => ({
          id: profile.id,
          screenname: profile.screenname,
          avatarUrl: profile.avatar_url,
          friendInvites: profile.friend_invites || 0,
          totalPoints: profile.total_points || 0,
          loginStreak: profile.activity_streak || 0,
          timeSpent: profile.time_spent || 0,
          rank: index + 1
        }));

        setLeaderboard(rankedProfiles);
      } catch (err) {
        console.error('Error fetching leaderboard:', err);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchLeaderboard();
    }
  }, [isOpen, user, activeTab, scope]);

  if (!isOpen) return null;

  const getMedalColor = (rank: number) => {
    switch (rank) {
      case 1:
        return '🥇';
      case 2:
        return '🥈';
      case 3:
        return '🥉';
      default:
        return '';
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-accent p-8 rounded-[2px] shadow-brutal w-full max-w-md relative max-h-[90vh] flex flex-col"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-highlight"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-white mb-2">Leaderboard</h2>
          <p className="text-white/80">
            {scope === 'friends' ? 'Compete with friends' : 'Global rankings'} across different categories!
          </p>
          <div className="flex justify-center gap-2 mt-4">
            <button
              onClick={() => setScope('friends')}
              className={`button-3d py-2 px-4 transition-colors ${
                scope === 'friends' ? 'bg-highlight text-black' : 'bg-white/10 text-white'
              }`}
            >
              Friends
            </button>
            <button
              onClick={() => setScope('global')}
              className={`button-3d py-2 px-4 transition-colors ${
                scope === 'global' ? 'bg-highlight text-black' : 'bg-white/10 text-white'
              }`}
            >
              Global
            </button>
          </div>
        </div>

        <div className="overflow-y-auto">
        <div className="grid grid-cols-4 gap-2 mb-8">
          <button
            onClick={() => setActiveTab('invites')}
            className={`flex flex-col items-center p-2 rounded-[2px] ${
              activeTab === 'invites' ? 'bg-highlight text-black' : 'bg-white/10 text-white'
            }`}
          >
            <Users className="w-5 h-5 mb-1" />
            <span className="text-sm">Invites</span>
          </button>
          <button
            onClick={() => setActiveTab('points')}
            className={`flex flex-col items-center p-2 rounded-[2px] ${
              activeTab === 'points' ? 'bg-highlight text-black' : 'bg-white/10 text-white'
            }`}
          >
            <Trophy className="w-5 h-5 mb-1" />
            <span className="text-sm">Points</span>
          </button>
          <button
            onClick={() => setActiveTab('streak')}
            className={`flex flex-col items-center p-2 rounded-[2px] ${
              activeTab === 'streak' ? 'bg-highlight text-black' : 'bg-white/10 text-white'
            }`}
          >
            <Flame className="w-5 h-5 mb-1" />
            <span className="text-sm">Streak</span>
          </button>
          <button
            onClick={() => setActiveTab('time')}
            className={`flex flex-col items-center p-2 rounded-[2px] ${
              activeTab === 'time' ? 'bg-highlight text-black' : 'bg-white/10 text-white'
            }`}
          >
            <Clock className="w-5 h-5 mb-1" />
            <span className="text-sm">Time</span>
          </button>
        </div>

        {loading ? (
          <div className="text-center text-white/80 py-4">Loading leaderboard...</div>
        ) : (
          <div className="space-y-1">
            {leaderboard.map((entry) => (
              <div
                key={entry.id}
                className={`bg-white/10 p-2 rounded-[2px] flex items-center gap-2 ${
                  entry.id === user?.id ? 'ring-2 ring-highlight' : ''
                }`}
              >
                <div className="w-8 text-center text-xl">{getMedalColor(entry.rank || 0)}</div>
                
                {entry.avatarUrl ? (
                  <img
                    src={entry.avatarUrl}
                    alt={entry.screenname || 'User'}
                    className="w-10 h-10 rounded-full object-cover"
                    crossOrigin="anonymous"
                  />
                ) : (
                  <div className="w-10 h-10 rounded-full bg-white/20 flex items-center justify-center">
                    <span className="text-white/60 text-xl">
                      {(entry.screenname || 'A')[0].toUpperCase()}
                    </span>
                  </div>
                )}

                <div className="flex-1">
                  <p className="font-bold text-white">
                    {entry.screenname || 'Anonymous'}
                    {entry.id === user?.id && ' (You)'}
                  </p>
                  <p className="text-white/60 text-sm">
                    {activeTab === 'invites' && (
                      <>{entry.friendInvites} invites</>
                    )}
                    {activeTab === 'points' && (
                      <>{entry.totalPoints} points</>
                    )}
                    {activeTab === 'streak' && (
                      <>{entry.loginStreak} day streak</>
                    )}
                    {activeTab === 'time' && (
                      <>
                        {Math.floor(entry.timeSpent / 86400)}d{' '}
                        {Math.floor((entry.timeSpent % 86400) / 3600)}h{' '}
                        {Math.floor((entry.timeSpent % 3600) / 60)}m{' '}
                        {Math.floor(entry.timeSpent % 60)}s
                      </>
                    )}
                  </p>
                </div>

                <div className="text-2xl font-bold text-white/80">
                  #{entry.rank}
                </div>
              </div>
            ))}

            {leaderboard.length === 0 && (
              <div className="text-center text-white/80 py-4">
                <p>No friends yet!</p>
                <p className="text-sm">
                  {scope === 'friends' 
                    ? 'Invite friends to compete for the longest streak.'
                    : 'Be the first to make it to the leaderboard!'}
                </p>
              </div>
            )}
          </div>
        )}
        </div>
      </motion.div>
    </div>
  );
}