import React, { useState, useEffect, useCallback, useRef } from "react";
import { ArrowLeft } from "lucide-react";
import { KeyboardButton } from "./KeyboardButton";
import { LongKeyboardButton } from "./LongKeyboardButton";
import { FlashCard, FlashCardSet } from "../types";
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import Header from './Header';
import { motion, AnimatePresence } from 'framer-motion';
import { playSound, initAudio } from '../lib/audio';

interface ButtonzGameProps {
  cards: FlashCard[];
  currentSet: FlashCardSet | null;
  onExit: () => void;
}

export default function ButtonzGame({ cards, currentSet, onExit }: ButtonzGameProps) {
  const { user } = useAuth();
  const [gameStartTime] = useState(Date.now());
  const [allCards, setAllCards] = useState<FlashCard[]>([]);
  const [currentWords, setCurrentWords] = useState<string[]>([]);
  const [currentDefinitions, setCurrentDefinitions] = useState<string[]>([]);
  const [selectedWord, setSelectedWord] = useState<string | null>(null);
  const [selectedDefinition, setSelectedDefinition] = useState<string | null>(null);
  const [score, setScore] = useState(0);
  const [matchedPairs, setMatchedPairs] = useState<Set<string>>(new Set());
  const [error, setError] = useState<string | null>(null);
  const [timeLeft, setTimeLeft] = useState(90); // Keep as seconds for calculations
  const [isGameActive, setIsGameActive] = useState(true);
  const [matchedWord, setMatchedWord] = useState<string | null>(null);
  const [matchedDefinition, setMatchedDefinition] = useState<string | null>(null);
  const [tempMatchedPairs, setTempMatchedPairs] = useState<Set<string>>(new Set());

  const BUTTONS_PER_SIDE = 6;

  useEffect(() => {
    initAudio();
  }, []);

  useEffect(() => {
    if (currentSet) {
      const setCards = cards.filter(card => card.setId === currentSet.id);
      if (setCards.length === 0) {
        setError('No cards found in this set');
        return;
      }
      setAllCards(setCards);
      updateDisplayedCards(setCards);
    }
  }, [cards, currentSet]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isGameActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            setIsGameActive(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isGameActive]);

  const updateDisplayedCards = useCallback((availableCards: FlashCard[]) => {
    const selectedCards = availableCards.slice(0, BUTTONS_PER_SIDE);
    
    setCurrentWords(selectedCards.map(card => card.word));
    setCurrentDefinitions(selectedCards.map(card => card.definition));
  }, []);

  const handleMatch = useCallback(async (isCorrect: boolean) => {
    if (isCorrect) {
      // Play success sound
      playSound('success');
      
      setScore(prev => prev + 1);
      
      // First set matched items to trigger fade out
      setMatchedWord(selectedWord);
      setMatchedDefinition(selectedDefinition);
      setTempMatchedPairs(new Set([selectedWord]));
      
      // Add a small delay before updating cards
      if (selectedWord && selectedDefinition) {
        setTimeout(() => {
          updateDisplayedCards(allCards);
          setMatchedWord(null);
          setMatchedDefinition(null);
          setTempMatchedPairs(new Set());
          // Shuffle after updating cards
          setCurrentWords(prev => [...prev].sort(() => Math.random() - 0.5));
          setCurrentDefinitions(prev => [...prev].sort(() => Math.random() - 0.5));
        }, 300);
      }
    } else {
      // Play mistake sound
      playSound('mistake');
      
      setScore(prev => prev - 1);
    }

    // Update score and time_spent in database
    if (user) {
      try {
        const duration = Math.floor((Date.now() - gameStartTime) / 1000);
        
        // First, save the game score
        const { error: insertError } = await supabase.from('game_scores').insert({
          user_id: user.id,
          game_type: 'buttonz',
          score: isCorrect ? 1 : -1,
          duration: duration
        });

        if (insertError) throw insertError;

        // Fetch the current time_spent
        const { data: profile, error: fetchError } = await supabase
          .from('profiles')
          .select('time_spent')
          .eq('id', user.id)
          .single();

        if (fetchError) throw fetchError;

        const newTimeSpent = (profile?.time_spent || 0) + duration;

        // Update the time_spent
        const { error: updateError } = await supabase
          .from('profiles')
          .update({ time_spent: newTimeSpent })
          .eq('id', user.id);

        if (updateError) throw updateError;
      } catch (err) {
        console.error('Error saving score or updating time spent:', err);
      }
    }

    // Reset selections
    setSelectedWord(null);
    setSelectedDefinition(null);
  }, [selectedWord, selectedDefinition, user, gameStartTime]);

  useEffect(() => {
    if (selectedWord && selectedDefinition) {
      const isMatch = allCards.some(
        card => card.word === selectedWord && card.definition === selectedDefinition
      );
      handleMatch(isMatch);
    }
  }, [selectedWord, selectedDefinition, allCards, handleMatch]);

  if (error) {
    return (
      <div className="h-screen bg-primary flex items-center justify-center">
        <div className="text-center">
          <p className="text-2xl text-white mb-4">{error}</p>
          <button onClick={onExit} className="button-3d">
            Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen bg-primary flex flex-col">
      <div className="w-full h-full max-w-[800px] mx-auto flex flex-col p-4 gap-8">
        <Header
          showBackButton
          onBack={onExit}
          score={score}
        />

        <div className="text-center font-luckiest text-6xl text-highlight mb-4">
          {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}
        </div>

        {!isGameActive && (
          <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50">
            <div className="bg-accent p-8 rounded-[2px] text-center">
              <h2 className="text-4xl font-bold text-white mb-4">Time's Up!</h2>
              <p className="text-2xl text-white mb-6">Final Score: {score}</p>
              <button onClick={onExit} className="button-3d">
                Exit Game
              </button>
            </div>
          </div>
        )}

        <div className="flex-1 overflow-y-auto scrollbar-hide">
          <div className="flex gap-2 justify-center items-start mt-8 pb-8">
            <div className="flex flex-col gap-1">
              {currentWords.map(word => (
                <KeyboardButton
                  key={word}
                  label={word}
                  onClick={() => setSelectedWord(word)}
                  isActive={selectedWord === word}
                  isMatched={tempMatchedPairs.has(word) || matchedPairs.has(word)}
                />
              ))}
            </div>

            <div className="flex flex-col gap-1">
              {currentDefinitions.map(definition => (
                <LongKeyboardButton
                  key={definition}
                  label={definition}
                  onClick={() => setSelectedDefinition(definition)}
                  isActive={selectedDefinition === definition}
                  isMatched={tempMatchedPairs.has(
                    allCards.find(card => card.definition === definition)?.word || ""
                  ) || matchedPairs.has(
                    allCards.find(card => card.definition === definition)?.word || ""
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}