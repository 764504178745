import React from 'react';

export function Logo() {
  return (
    <svg width="150" height="51" viewBox="0 0 150 51" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-12 w-auto">
      <path d="M139.648 39.5904L139.754 39.6445H139.874H141.315H141.815V39.1445V33.1758H142.757H143.68V38.2793V38.7793H144.18H145.056L146.839 39.7214L146.948 39.7793H147.072H148.514H149.014V39.2793V20.4326V19.9326H148.514H147.572V19.7119V19.2119H147.072H146.14V19V18.5H145.64H144.198H143.698V19V27.1396H142.757H141.815V20.4326V19.9326H141.315H140.374V19.7119V19.2119H139.874H138.933V19V18.5H138.433H137H136.5V19V38.4238V38.9238H137H138.334L139.648 39.5904Z" fill="#1817F6" stroke="white"/>
      <path d="M124.077 38.4326V38.9326H124.577H132.505H133.005V38.4326V38.2207H133.938H134.438V37.7207V37.5H134.658H135.158V37V36.0586H135.379H135.879V35.5586V32.6758V32.1758H135.379H135.158V31.9551V31.4551H134.658H134.438V31.2344V30.7344H133.938H133.005V30.5137V30.0137H132.505H130.843V29.793V29.293H130.343H129.401V29.0811V28.8604V28.5811V28.3604V28.1396H129.622H129.843V28.3604V28.8604H130.343H134.658H135.158V28.3604V24.0361V23.5361H134.658H133.733V23.3154V22.8154H133.233H132.301V22.5947V22.0947H131.801H128.901H128.401V22.5947V22.8154H127.46H126.96V23.3154V23.5361H126.019H125.519V24.0361V24.2568H125.298H124.798V24.7568V24.9775H124.577H124.077V25.4775V26.4189H123.865H123.365V26.9189V29.793V30.293H123.865H124.077V31.2344V31.7344H124.577H124.798V31.9551V32.4551H125.298H125.519V32.6758V33.1758H126.019H126.96V33.3965V33.8965H127.46H129.122V34.1172V34.3379H124.577H124.077V34.8379V38.4326Z" fill="#1817F6" stroke="white"/>
      <path d="M98.8965 38.4326V38.9326H99.3965H104.433H109.469H109.969V38.4326V34.8379V34.3379H109.469H104.212V21.1533V20.6533H103.712H102.771V20.4326V19.9326H102.271H101.329V19.7207V19.2207H100.829H99.3965H98.8965V19.7207V38.4326Z" fill="#1817F6" stroke="white"/>
      <path d="M115.815 31.8965V30.0137H116.036H116.257V31.8965H115.815ZM113.596 39.3416L113.67 39.3652H113.748H113.874H115.315H115.815V38.8652V36.5H117.698V37.4326V37.9326H118.198H122.514H123.014V37.4326V34.5586V34.0586H122.514H122.293V31.6758V31.1758H121.793H121.572V28.793V28.293H121.072H120.852V25.9189V25.4189H120.352H120.14V23.0361V22.5361H119.64H119.419V20.7543V20.2543H118.919H118.698V19V18.5H118.198H117.604V18V17.5H117.104H113.874H112.104H111.604V18V18.5H111.104H110.606L110.604 18.9972L110.5 37.4298H110.5V37.4326V38.5V39H111H112.526L113.596 39.3416Z" fill="#1817F6" stroke="white"/>
      <path d="M88.6009 39.1248L88.7284 39.2119H88.8828H90.3242H90.8242V38.7119V31.3018H93.9189H94.4189V30.8018V30H94.5H95V29.5V28.5H95.5H96V28V27.207V26.707H95.5H90.8242V24.8242H97.5225H98.0225V24.3242V21.4414V20V19.5H97.5225H86H85.5V20V21.4414V22.8828V24.3242V25.0449V37.8137V38.3137H86H87.413L88.6009 39.1248Z" fill="#1817F6" stroke="white"/>
      <path d="M51.5 39V39.5H52H64H64.5V39V35.2553V34.7553H64H57.6473V32.7523H64H64.5V32.2523V30.3754V29.8754H64H63.2132V29.3337V28.8337H62.7132H61.9061V28.5076V28.0076H61.4061H57.6473V26.0046H64H64.5V25.5046V24.0031V22.5015V21V20.5H64H54.6376H52H51.5V21V23.9817V24.646V25.528V39Z" fill="#1817F6" stroke="white"/>
      <path d="M34.2861 40.3172L34.3877 40.3652H34.5H35.2256H36.667H37.167V39.8652V33.1758H37.3877H37.6084V34.1172V34.6172H38.1084H38.3291V35.5586V36.0586H38.8291H39.0498V37V37.5H39.5498H39.7705V38.4326V38.9326H40.2705H40.9912H41.4912V38.4326V37.5H41.7031H42.2031V37V36.0586H42.4238H42.9238V35.5586V34.6172H43.1445H43.6445V34.1172V33.1758H43.8652H44.0859V39V39.5H44.5859H45.3661L46.7502 40.2983L46.8661 40.3652H47H47.4688H48.9102H49.4102V39.8652V21.1533V20.6533H48.9102H44.5859H44.0859V21.1533V22.0947H43.8652H43.3652V22.5947V23.5361H43.1445H42.6445V24.0361V24.9775H42.4238H41.9238V25.4775V26.4189H41.7031H41.2031V26.9189V27.8604H40.9912H40.7705H40.4912H40.2705H40.0498V26.9189V26.4189H39.5498H39.3291V25.4775V24.9775H38.8291H38.6084V24.0361V23.5361H38.1084H37.8877V22.5947V22.0947H37.3877H37.167V21.1533V20.6533H36.667H32.3516H31.8516V21.1533V39.3135V39.8135H32.3516H33.2218L34.2861 40.3172Z" fill="#1817F6" stroke="white"/>
      <path d="M24.2119 32.8965V31.0137H24.4326H24.6533V32.8965H24.2119ZM21.9928 40.3416L22.067 40.3652H22.1448H22.2705H23.7119H24.2119V39.8652V37.5H26.0947V38.4326V38.9326H26.5947H30.9102H31.4102V38.4326V35.5586V35.0586H30.9102H30.6895V32.6758V32.1758H30.1895H29.9688V29.793V29.293H29.4688H29.248V26.9189V26.4189H28.748H28.5361V24.0361V23.5361H28.0361H27.8154V21.7543V21.2543H27.3154H27.0947V20V19.5H26.5947H26V19V18.5H25.5H22.2705H20.5H20V19V19.5H19.5H19.0028L19 19.9972L18.8965 38.4298H18.8965V38.4326V39.5V40H19.3965H20.9222L21.9928 40.3416Z" fill="#1817F6" stroke="white"/>
      <path d="M5.85708 39V39.5H6.35708H12.6088H13.1088V39V38.7771H14.3982H14.8982V38.2771V38.0453H15.2928H15.7928V37.5453V37.3135H16.1875H16.6875V36.8135V35.8499H17.7141H18.2141V35.3499V28.7727V28.2727H17.7141H10.8304H10.3304V28.7727V32.4227V32.9227H10.8304H12.1088V33.3863H11.7251H11.2251V33.8863V34.1181H8.64642V33.8863V33.3863H8.14642H7.75175V32.4227V31.9227H7.25175H6.85708V30.0045H7.25175H7.75175V29.5045V28.5409H8.14642H8.64642V28.0409V27.0773H9.04108H9.54108V26.5773V26.3455H9.93575H10.4357V25.8455V25.6137H10.8304H11.2251V27.3091V27.8091H11.7251H16.1875H16.6875V27.3091V20.7229V20.2229H16.1875H15.7928V20V19.5H15.2928H10.8304H10.3304V20V20.2229H9.04108H8.54108V20.7229V20.9547H7.25175H6.75175V21.4547V21.6865H6.35708H5.85708V22.1865V22.4183H5.46242H4.96242V22.9183V23.1501H4.56775H4.06775V23.6501V23.8819H3.67309H3.17309V24.3819V25.3455H2.77842H2.27842V25.8455V26.8091H1.88376H1.38376V27.3091V28.2727H1H0.5V28.7727V33.1545V33.6545H1H1.38376V35.3499V35.8499H1.88376H2.27842V36.8135V37.3135H2.77842H3.17309V37.5453V38.0453H3.67309H4.06775V38.2771V38.7771H4.56775H5.85708V39Z" fill="#1817F6" stroke="white"/>
      <path d="M74.1886 14.838L87.6168 13.1863L76.7744 48.6829L73.4433 29.418L73.2813 28.4811L72.6012 29.1455L67.9468 33.6923L60.6751 13.6238L77.1144 4.98007L73.6595 14.1657L73.3688 14.9388L74.1886 14.838Z" fill="#D1F23D" stroke="black"/>
    </svg>
  );
}