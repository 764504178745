import React, { useState } from 'react';
import { supabase } from '../lib/supabase';
import { useNavigate } from 'react-router-dom';
import { Lock } from 'lucide-react';

export default function AdminLogin() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const { data: { session }, error: authError } = await supabase.auth.getSession();
      
      if (!session?.user) {
        throw new Error('You must be logged in to access admin panel');
      }

      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('is_admin')
        .eq('id', session.user.id)
        .single();

      if (profileError) throw profileError;
      
      if (!profile?.is_admin) {
        throw new Error('Unauthorized access');
      }

      // Verify admin password
      const { data: adminConfig, error: configError } = await supabase
        .from('admin_config')
        .select('password_hash')
        .single();

      if (configError) throw configError;

      const { data: verified, error: verifyError } = await supabase
        .rpc('verify_admin_password', { input_password: password });

      if (verifyError || !verified) {
        throw new Error('Invalid admin password');
      }

      // Store admin session
      localStorage.setItem('admin_session', 'true');
      navigate('/admin/dashboard');
    } catch (err) {
      console.error('Admin login error:', err);
      setError(err instanceof Error ? err.message : 'Failed to login');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-primary flex items-center justify-center p-4">
      <div className="w-full max-w-md">
        <div className="bg-accent p-8 rounded-[2px] shadow-brutal">
          <div className="text-center mb-8">
            <div className="w-16 h-16 bg-highlight rounded-full flex items-center justify-center mx-auto mb-4">
              <Lock className="w-8 h-8" />
            </div>
            <h1 className="text-2xl font-bold text-white">Admin Login</h1>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-white mb-2">
                Admin Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 rounded-[2px] bg-white text-black"
                required
              />
            </div>

            {error && (
              <div className="bg-primary/20 border-2 border-primary text-white p-3 rounded-[2px]">
                {error}
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="button-3d w-full"
            >
              {loading ? 'Verifying...' : 'Login'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}