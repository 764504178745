import React from 'react';
import { FlashCard } from '../types';
import { Brain, Calendar, Clock } from 'lucide-react';

interface StatsProps {
  cards: FlashCard[];
}

export default function Stats({ cards }: StatsProps) {
  const totalCards = cards.length;
  // Count cards that have been swiped right (easeFactor > 2.5)
  const masteredCards = cards.filter(card => card.easeFactor > 2.5).length;
  const cardsForToday = totalCards - masteredCards;

  return (
    <div className="grid grid-cols-3 gap-2 md:gap-8 w-full max-w-2xl mx-auto mb-12">
      <div className="bg-accent rounded-[2px] p-3 md:p-6 shadow-brutal">
        <Brain className="w-6 h-6 md:w-8 md:h-8 text-highlight mb-2 md:mb-4" />
        <div>
          <p className="text-sm md:text-lg text-white/80">Total Cards</p>
          <p className="text-xl md:text-3xl font-bold text-white">{totalCards}</p>
        </div>
      </div>
      <div className="bg-accent rounded-[2px] p-3 md:p-6 shadow-brutal">
        <Calendar className="w-6 h-6 md:w-8 md:h-8 text-highlight mb-2 md:mb-4" />
        <div>
          <p className="text-sm md:text-lg text-white/80">Due Today</p>
          <p className="text-xl md:text-3xl font-bold text-white">{cardsForToday}</p>
        </div>
      </div>
      <div className="bg-accent rounded-[2px] p-3 md:p-6 shadow-brutal">
        <Clock className="w-6 h-6 md:w-8 md:h-8 text-highlight mb-2 md:mb-4" />
        <div>
          <p className="text-sm md:text-lg text-white/80">Mastered</p>
          <p className="text-xl md:text-3xl font-bold text-white">{masteredCards}</p>
        </div>
      </div>
    </div>
  );
}