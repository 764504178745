import React from 'react';
import { ArrowLeft } from 'lucide-react';
import Header from './Header';

interface CubeyGameProps {
  onExit: () => void;
}

export default function CubeyGame({ onExit }: CubeyGameProps) {
  return (
    <div className="min-h-screen bg-primary flex flex-col">
      <div className="w-full h-full max-w-[800px] mx-auto flex flex-col p-4 gap-8">
        <Header
          showBackButton
          onBack={onExit}
        />

        <div className="flex-1 flex items-center justify-center">
          <div className="text-center">
            <h1 className="text-6xl font-bold text-highlight mb-8">Coming Soon!</h1>
            <p className="text-2xl text-white">Get ready for an exciting new way to learn</p>
          </div>
        </div>
      </div>
    </div>
  );
}