import { useState, useEffect } from 'react';
import { X, Copy, Check, UserPlus } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

interface ReferralModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Friend {
  id: string;
  screenname: string | null;
  avatarUrl: string | null;
  createdAt: string;
}

export default function ReferralModal({ isOpen, onClose }: ReferralModalProps) {
  const { user, profile } = useAuth();
  const [_copied, setCopied] = useState(false);
  const [friends, setFriends] = useState<Friend[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [friendCode, setFriendCode] = useState<string>('');
  const [addingFriend, setAddingFriend] = useState(false);
  const [friendCodeError, setFriendCodeError] = useState<string | null>(null);
  const [userFriendCode, setUserFriendCode] = useState<string | null>(null);
  const [copyingCode, setCopyingCode] = useState(false);

  useEffect(() => {
    if (isOpen && user) {
      fetchFriends();
      fetchUserFriendCode();
    }
  }, [isOpen, user]);

  useEffect(() => {
    const processReferral = async () => {
      if (!user) {
        console.log('No user logged in, skipping referral processing.');
        return;
      }
    
      const referrerId = localStorage.getItem('referrer_id');
      console.log(`Referrer ID from local storage: ${referrerId}, User ID: ${user.id}`);
    
      if (!referrerId || referrerId === user.id) {
        console.log('No valid referrer ID found or referrer is the same as the user.');
        return;
      }
    
      try {
        console.log(`Checking for existing referral: Referrer ID = ${referrerId}, Referred User ID = ${user.id}`);
        
        // Check if the referral already exists
        const { data: existingReferral, error: checkError } = await supabase
          .from('referrals')
          .select('id')
          .eq('referrer_id', referrerId)
          .eq('referred_id', user.id)
          .single();
    
        if (checkError && checkError.code !== 'PGRST116') {
          // Ignore the "No rows" error; handle others
          console.error('Error checking for existing referral:', checkError);
          return;
        }
    
        if (existingReferral) {
          console.log('Referral already exists, skipping insertion.');
          return;
        }
    
        // Insert into referrals table
        const { error: referralError } = await supabase.from('referrals').insert({
          referrer_id: referrerId,
          referred_id: user.id,
        });
    
        if (referralError) {
          console.error('Error inserting referral:', referralError);
          return;
        }
    
        // Insert mutual friendships into the friends table
        const { error: friendError1 } = await supabase.from('friends').insert({
          user_id: referrerId,
          friend_id: user.id,
        });
    
        const { error: friendError2 } = await supabase.from('friends').insert({
          user_id: user.id,
          friend_id: referrerId,
        });
    
        if (friendError1 || friendError2) {
          console.error('Error inserting friendships:', friendError1 || friendError2);
          return;
        }
    
        // Fetch current friend_invites count
        const { data: profile, error: fetchError } = await supabase
          .from('profiles')
          .select('friend_invites')
          .eq('id', referrerId)
          .single();
    
        if (fetchError) {
          console.error('Error fetching friend_invites:', fetchError);
          return;
        }
    
        const currentInvites = profile?.friend_invites || 0;
    
        // Update the friend_invites count
        const { error: updateError } = await supabase
          .from('profiles')
          .update({ friend_invites: currentInvites + 1 })
          .eq('id', referrerId);
    
        if (updateError) {
          console.error('Error updating friend_invites:', updateError);
          return;
        }
    
        console.log('Successfully incremented friend_invites for user:', referrerId);
    
        // Clear the referral code from local storage after processing
        localStorage.removeItem('referrer_id');
      } catch (error) {
        console.error('Unexpected error processing referral and friendships:', error);
      }
    };
    
  
    if (user) {
      processReferral();
    }
  }, [user]);
  
  
  
  
  
  
  

  const fetchUserFriendCode = async () => {
    if (!user) {
      console.log('No user found, skipping friend code fetch.');
      return;
    }
    
    try {
      console.log('Fetching profile for user ID:', user.id);
      setUserFriendCode(null);
      
      const { data, error } = await supabase
        .from('profiles')
        .select('id, screenname, friend_code')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Supabase error fetching profile:', error);
        console.error('Error details:', {
          code: error.code,
          message: error.message,
          details: error.details
        });
        throw error;
      }

      if (!data) {
        console.warn('No profile data found for user:', user.id);
        setUserFriendCode(null);
        return;
      }

      console.log('Profile data retrieved:', {
        id: data.id,
        screenname: data.screenname,
        hasFriendCode: !!data.friend_code
      });
      
      setUserFriendCode(data.friend_code || null);
    } catch (err) {
      console.error('Failed to load friend code:', err);
      setError('Failed to load friend code');
      setUserFriendCode(null);
    }
  };

  const fetchFriends = async () => {
    if (!user) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const { data: friendships, error: friendshipsError } = await supabase
        .from('friends')
        .select('friend_id')
        .eq('user_id', user.id);

      if (friendshipsError) throw friendshipsError;

      if (!friendships || friendships.length === 0) {
        setFriends([]);
        return;
      }

      const friendIds = friendships.map(f => f.friend_id);

      const { data: profiles, error: profilesError } = await supabase
        .from('profiles')
        .select('id, screenname, avatar_url, created_at')
        .in('id', friendIds);

      if (profilesError) throw profilesError;

      const formattedFriends = profiles.map(profile => ({
        id: profile.id,
        screenname: profile.screenname,
        avatarUrl: profile.avatar_url,
        createdAt: profile.created_at
      }));

      setFriends(formattedFriends);
    } catch (err) {
      setError('Failed to load friends list');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  const referralLink = `${window.location.origin}?ref=${profile?.id}`;
  console.log('Generated Referral Link:', referralLink);
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleAddFriend = async () => {
    if (!user || !friendCode.trim()) return;
    
    setFriendCodeError(null);
    try {
      setAddingFriend(true);
      setError(null);
      
      const { error } = await supabase.rpc('handle_friend_request', {
        input_code: friendCode.trim(),
        requesting_user_id: user.id,
      });
      
      if (error) {
        console.error('Error adding friend:', error);
      }

      setFriendCode('');
      setFriendCodeError(null);
      fetchFriends();
    } catch (err) {
      setFriendCodeError('Invalid friend code or user not found');
    } finally {
      setAddingFriend(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-accent p-8 rounded-[2px] shadow-brutal w-full max-w-md relative max-h-[80vh] overflow-y-auto"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-highlight"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-center mb-6">
          <span className="text-6xl mb-2 block">🤩</span>
          <h2 className="text-2xl font-bold text-white mb-2">Invite Friends</h2>
          <p className="text-white/80">
            Share this link with friends to invite them to join Game Flash!
          </p>
        </div>

        <div className="bg-white/10 p-4 rounded-[2px] flex items-center gap-2 mb-6">
          <div className="flex-1 text-white overflow-hidden overflow-ellipsis whitespace-nowrap">
            {referralLink}
          </div>
          <button
            onClick={handleCopy}
            className="button-3d py-2 px-4 flex items-center gap-2"
          >
            {_copied ? (
              <>
                <Check className="w-4 h-4" />
                Copied!
              </>
            ) : (
              <>
                <Copy className="w-4 h-4" />
                Copy
              </>
            )}
          </button>
        </div>

        <div className="bg-white/10 p-4 rounded-[2px] mb-6">
          <p className="text-white mb-2">Your Friend Code:</p>
          <div className="flex items-center gap-2">
            <div className="flex-1 bg-highlight/20 p-2 rounded-[2px] text-white font-mono">
              {userFriendCode === null ? 'Loading...' : userFriendCode || 'No code available'}
            </div>
            <button
              onClick={async () => {
                try {
                  setCopyingCode(true);
                  if (userFriendCode) {
                    await navigator.clipboard.writeText(userFriendCode);
                  }
                  setTimeout(() => setCopyingCode(false), 2000);
                } catch (err) {
                  console.error('Failed to copy code:', err);
                }
              }}
              className="button-3d py-2 px-4 flex items-center gap-2"
              disabled={!userFriendCode}
            >
              {copyingCode ? (
                <>
                  <Check className="w-4 h-4" />
                  Copied!
                </>
              ) : (
                <>
                  <Copy className="w-4 h-4" />
                  Copy
                </>
              )}
            </button>
          </div>
          <p className="text-white/60 text-sm mt-2">
            Share this code with friends to connect
          </p>
        </div>

        <div className="bg-white/10 p-4 rounded-[2px] mb-6">
          <p className="text-white mb-2">Add Friend by Code:</p>
          <div className="flex gap-2">
            <input
              type="text"
              value={friendCode}
              onChange={(e) => setFriendCode(e.target.value)}
              placeholder="Enter friend code"
              className={`flex-1 px-3 py-2 rounded-[2px] bg-white text-black ${
                friendCodeError ? 'ring-2 ring-primary' : ''
              }`}
            />
            <button
              onClick={handleAddFriend}
              disabled={addingFriend || !friendCode.trim()}
              className="button-3d py-2 px-4"
            >
              {addingFriend ? 'Adding...' : 'Add'}
            </button>
          </div>
          {friendCodeError && (
            <p className="text-primary text-sm mt-2">{friendCodeError}</p>
          )}
        </div>

        <div>
          <h3 className="text-xl font-bold text-white text-center mb-3">Your Friends</h3>
          
          {loading ? (
            <div className="text-center text-white/80 py-2">Loading friends...</div>
          ) : error ? (
            <div className="text-center text-red-400 py-2">{error}</div>
          ) : (
            <div className="bg-highlight rounded-[2px] p-2 shadow-brutal max-h-[160px] overflow-y-auto">
              {friends.length > 0 ? (
                <div className="space-y-1">
                  {friends.map(friend => (
                    <div 
                      key={friend.id}
                      className="flex items-center gap-3 bg-white/10 p-1.5 rounded-[2px]"
                    >
                      {friend.avatarUrl ? (
                        <img 
                          src={friend.avatarUrl}
                          alt={friend.screenname || 'Friend'}
                          className="w-10 h-10 rounded-full object-cover"
                          crossOrigin="anonymous"
                        />
                      ) : (
                        <div className="w-10 h-10 rounded-full bg-white/20 flex items-center justify-center">
                          <UserPlus className="w-5 h-5 text-white/60" />
                        </div>
                      )}
                      <div className="flex-1 min-w-0">
                        <p className="text-black font-bold truncate">
                          {friend.screenname || 'Anonymous Friend'}
                        </p>
                        <p className="text-black/60 text-xs">
                          Joined {new Date(friend.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center text-black py-2">
                  <p className="font-bold">No friends yet!</p>
                  <p className="text-sm">Share your invite link to add friends.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
}