import { useState } from "react"

interface LongKeyboardButtonProps {
  label: string
  onClick: () => void
  isActive: boolean
  isMatched: boolean
}

export function LongKeyboardButton({ label, onClick, isActive, isMatched }: LongKeyboardButtonProps) {
  const [isPressed, setIsPressed] = useState(false)

  return (
    <button
      className="relative h-[54px] w-[237px] select-none transition-transform"
      onClick={onClick}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onMouseLeave={() => setIsPressed(false)}
      onTouchStart={() => setIsPressed(true)}
      onTouchEnd={() => setIsPressed(false)}
    >
      {isMatched || isActive ? (
        <svg
          width="237"
          height="54"
          viewBox="0 0 237 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.25"
            y="4.25"
            width="236.5"
            height="49.5"
            rx="24.75"
            fill="#6D6D6D"
            stroke="black"
            strokeWidth="0.5"
          />
          <rect
            x="2.25"
            y="6.25"
            width="232.5"
            height="45.5"
            rx="22.75"
            fill="#CECECE"
            stroke="black"
            strokeWidth="0.5"
          />
          <rect
            x="2.25"
            y="0.25"
            width="232.5"
            height="45.5"
            rx="22.75"
            fill="#EBFF00"
            stroke="black"
            strokeWidth="0.5"
          />
        </svg>
      ) : (
        <>
          <svg
            width="237"
            height="54"
            viewBox="0 0 237 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ display: isPressed ? 'none' : 'block' }}
          >
            <rect
              x="0.25"
              y="4.25"
              width="236.5"
              height="49.5"
              rx="24.75"
              fill="#6D6D6D"
              stroke="black"
              strokeWidth="0.5"
            />
            <rect
              x="2.25"
              y="6.25"
              width="232.5"
              height="45.5"
              rx="22.75"
              fill="#CECECE"
              stroke="black"
              strokeWidth="0.5"
            />
            <rect
              x="2.25"
              y="0.25"
              width="232.5"
              height="45.5"
              rx="22.75"
              fill="#FBFBFB"
              stroke="black"
              strokeWidth="0.5"
            />
          </svg>

          <svg
            width="237"
            height="56"
            viewBox="0 0 237 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ display: isPressed ? 'block' : 'none' }}
          >
            <rect
              x="0.25"
              y="0.25"
              width="236.5"
              height="49.5"
              rx="24.75"
              fill="#6D6D6D"
              stroke="black"
              strokeWidth="0.5"
            />
            <rect
              x="5.25"
              y="2.25"
              width="226.5"
              height="45.5"
              rx="22.75"
              fill="#CECECE"
              stroke="black"
              strokeWidth="0.5"
            />
            <rect
              x="2.25"
              y="2.25"
              width="232.5"
              height="45.5"
              rx="22.75"
              fill="#FBFBFB"
              stroke="black"
              strokeWidth="0.5"
            />
          </svg>
        </>
      )}
      <span className="absolute inset-0 flex items-center justify-center text-xs font-medium px-4">
        <span className="text-black -translate-y-1">{label}</span>
      </span>
    </button>
  )
}