import React from 'react';

interface GameQuestionProps {
  word: string;
  options: string[];
  correctAnswer: string;
  isCorrect: boolean | null;
  onAnswer: (answer: string) => void;
}

export default function GameQuestion({ word, options, correctAnswer, isCorrect, onAnswer }: GameQuestionProps) {
  return (
    <div className="w-full bg-accent rounded-[2px] p-4 shadow-brutal">
      <h2 className="text-[2.5rem] font-bold text-white mb-4 text-center">
        {word}
      </h2>
      
      <div className="grid grid-cols-2 gap-4">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => onAnswer(option)}
            className={`w-[95%] mx-auto p-2 text-center rounded-[2px] transition-all duration-75 ease-out transform active:scale-95 
              ${
                isCorrect !== null && option === correctAnswer
                  ? 'bg-highlight text-black shadow-none translate-x-2 translate-y-2'
                  : 'bg-highlight text-black shadow-brutal hover:translate-y-0.5 hover:translate-x-0.5 hover:shadow-[8px_8px_0_0_rgba(0,0,0,1)]'
              }`}
          >
            <span className="text-base font-bold">{option}</span>
          </button>
        ))}
      </div>
    </div>
  );
}