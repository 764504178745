import React, { useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Camera } from 'lucide-react';
import AuthModal from './AuthModal';
import ScreennameModal from './ScreennameModal';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../lib/supabase';

export default function UserMenu() {
  const { user, profile, updateAvatar } = useAuth();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showScreennameModal, setShowScreennameModal] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageError, setImageError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showEnlargedAvatar, setShowEnlargedAvatar] = useState(false);

  // Memoize the profile data to prevent unnecessary re-renders
  const profileData = React.useMemo(() => ({
    avatarUrl: profile?.avatarUrl,
    screenname: profile?.screenname
  }), [profile?.avatarUrl, profile?.screenname]);

  const handleImageError = () => {
    setImageError(true);
    console.error('Failed to load avatar image');
  };

  const handleAvatarClick = () => {
    if (profileData.avatarUrl) {
      setShowEnlargedAvatar(true);
    } else {
      fileInputRef.current?.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !user) return;

    try {
      setUploading(true);
      const fileExt = file.name.split('.').pop();
      const filePath = `${user.id}/avatar.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, file, { upsert: true });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('avatars')
        .getPublicUrl(filePath);

      await updateAvatar(publicUrl);
    } catch (error) {
      console.error('Error uploading avatar:', error);
      alert('Error uploading avatar. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      {user ? (
        <div className="flex items-center gap-2">
          <div
            className="text-white hover:text-highlight transition-colors bg-[#FF1C60] px-4 py-2 rounded-[2px] flex items-center gap-2 shadow-brutal"
          >
            <div onClick={handleAvatarClick}>
              {profileData.avatarUrl ? (
                <img 
                  src={profileData.avatarUrl} 
                  alt="Avatar"
                  crossOrigin="anonymous"
                  onError={handleImageError}
                  className={`w-6 h-6 rounded-full object-cover cursor-pointer ${uploading ? 'opacity-50' : ''}`}
                />
              ) : (imageError || !profileData.avatarUrl) && (
                <div
                  className={`w-6 h-6 rounded-full bg-white/10 flex items-center justify-center hover:bg-white/20 transition-colors cursor-pointer ${uploading ? 'opacity-50' : ''}`}
                >
                  {uploading ? (
                    <span className="animate-spin">⌛</span>
                  ) : (
                    <Camera className="w-4 h-4" />
                  )}
                </div>
              )}
            </div>
            <button
              onClick={() => setShowScreennameModal(true)}
            >
              <span>{profileData.screenname || 'Set Username'}</span>
            </button>
          </div>
        </div>
      ) : (
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setShowAuthModal(true)}
          className="button-3d"
        >
          Sign Up / Login
        </motion.button>
      )}

      <AnimatePresence>
        {showEnlargedAvatar && profileData.avatarUrl && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/80 flex items-center justify-center z-50"
            onClick={() => setShowEnlargedAvatar(false)}
          >
            <motion.img
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.5 }}
              src={profileData.avatarUrl}
              alt="Enlarged Avatar"
              className="max-w-[80vw] max-h-[80vh] rounded-lg object-contain"
              crossOrigin="anonymous"
            />
          </motion.div>
        )}
      </AnimatePresence>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />

      <ScreennameModal
        isOpen={showScreennameModal}
        onClose={() => setShowScreennameModal(false)}
      />

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="hidden"
      />
    </>
  );
}