import React, { useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { X, Loader2, Camera, Upload } from 'lucide-react';
import { motion } from 'framer-motion';
import { supabase } from '../lib/supabase';
import clsx from 'clsx';

interface ScreennameModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ScreennameModal({ isOpen, onClose }: ScreennameModalProps) {
  const { user, profile, updateScreenname, updateAvatar, signOut } = useAuth();
  const [screenname, setScreenname] = useState(profile?.screenname || '');
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  if (!isOpen) return null;

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !user) return;

    try {
      setUploading(true);
      setError('');

      // Validate file type
      if (!file.type.startsWith('image/')) {
        throw new Error('Please upload an image file');
      }

      // Validate file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        throw new Error('Image must be less than 5MB');
      }

      const fileExt = file.name.split('.').pop();
      const fileName = `${user.id}/${Date.now()}.${fileExt}`;

      // Upload file to storage
      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) {
        console.error('Upload error:', uploadError);
        throw new Error('Failed to upload image');
      }

      // Get the public URL
      const { data: { publicUrl } } = supabase.storage
        .from('avatars')
        .getPublicUrl(fileName);

      // Add crossorigin attribute to the URL
      const finalUrl = new URL(publicUrl);
      finalUrl.searchParams.set('crossorigin', 'anonymous');

      // Update profile with new avatar URL
      await updateAvatar(finalUrl.toString());
      
      // Clear the file input
      e.target.value = '';
    } catch (err) {
      console.error('Error uploading avatar:', err);
      setError(err instanceof Error ? err.message : 'Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('loading');
    setError('');

    try {
      // Check if screenname is already taken
      const { data: existingUser } = await supabase
        .from('profiles')
        .select('id')
        .eq('screenname', screenname)
        .neq('id', user?.id || '')
        .single();

      if (existingUser) {
        throw new Error('This screenname is already in use. Please choose a different one.');
      }

      await updateScreenname(screenname);
      onClose();
    } catch (err) {
      console.error('Update screenname error:', err);
      setStatus('error');
      setError(
        err instanceof Error 
          ? err.message 
          : 'This screenname is already in use. Please choose a different one.'
      );
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      onClose();
    } catch (err) {
      console.error('Sign out error:', err);
      setError(err instanceof Error ? err.message : 'Failed to sign out');
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-accent p-8 rounded-[2px] shadow-brutal w-full max-w-md relative"
      >
        <button
          type="button"
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-highlight"
        >
          <X className="w-6 h-6" />
        </button>

        <h2 className="text-2xl font-bold text-white mb-6">Personalize Your Profile</h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex flex-col items-center mb-8">
            <button
              type="button"
              onClick={handleImageClick}
              disabled={uploading}
              className="group relative w-24 h-24 rounded-full overflow-hidden border-4 border-highlight mb-2"
            >
              {profile?.avatarUrl ? (
                <img 
                  src={profile.avatarUrl} 
                  alt="Profile"
                  className="w-full h-full object-cover"
                  crossOrigin="anonymous"
                />
              ) : (
                <div className="w-full h-full bg-white/10 flex items-center justify-center">
                  <Camera className="w-8 h-8 text-white" />
                </div>
              )}
              <div className="absolute inset-0 bg-black/50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                <Upload className="w-6 h-6 text-white" />
              </div>
            </button>
            <p className="text-white/80 text-sm">
              {uploading ? 'Uploading...' : 'Click to upload profile picture'}
            </p>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden"
            />
          </div>

          <div>
            <label htmlFor="screenname" className="block text-white mb-2">
              Screenname
            </label>
            <input
              type="text"
              id="screenname"
              value={screenname}
              onChange={(e) => setScreenname(e.target.value)}
              className={clsx(
                "w-full px-4 py-3 rounded-[2px] bg-white text-black",
                "focus:outline-none focus:ring-2 focus:ring-highlight",
                "transition-shadow duration-200",
                status === 'error' && "ring-2 ring-primary"
              )}
              placeholder="Enter your screenname"
              required
              minLength={3}
              maxLength={30}
              pattern="[a-zA-Z0-9_-]+"
            />
            <p className="text-white/80 text-sm mt-1">
              3-30 characters, letters, numbers, underscores, and hyphens only
            </p>
          </div>

          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-primary/20 border-2 border-primary text-white p-3 rounded-[2px]"
            >
              {error}
            </motion.div>
          )}

          <button
            type="submit"
            disabled={status === 'loading' || uploading}
            className={clsx(
              "button-3d w-full relative",
              "flex items-center justify-center",
              (status === 'loading' || uploading) && "opacity-80"
            )}
          >
            {status === 'loading' ? (
              <>
                <Loader2 className="w-5 h-5 animate-spin mr-2" />
                Saving...
              </>
            ) : (
              "Save Profile"
            )}
          </button>

          <button
            type="button"
            onClick={handleSignOut}
            className="w-full py-3 bg-[#FF3800] text-white rounded-[2px] font-bold shadow-brutal hover:opacity-90 transition-opacity"
          >
            Log Out
          </button>
        </form>
      </motion.div>
    </div>
  );
}