import React, { useState, useEffect } from 'react';
import { FlashCard, FlashCardSet } from './types';
import FileUpload from './components/FileUpload';
import FlashCardView from './components/FlashCardView';
import Stats from './components/Stats';
import SetsPage from './components/SetsPage';
import VocabularyGame from './components/VocabularyGame';
import ButtonzGame from './components/ButtonzGame';
import MatchingGame from './components/MatchingGame';
import CubeyGame from './components/CubeyGame';
import UserMenu from './components/UserMenu';
import { useAuth } from './contexts/AuthContext';
import { supabase } from './lib/supabase';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';

function App() {
  const { user, loading: authLoading } = useAuth();
  const [sets, setSets] = useState<FlashCardSet[]>([]);
  const [cards, setCards] = useState<FlashCard[]>([]);
  const [currentSet, setCurrentSet] = useState<FlashCardSet | null>(null);
  const [currentCard, setCurrentCard] = useState<FlashCard | null>(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [isQuizMode, setIsQuizMode] = useState(false);
  const [isGameMode, setIsGameMode] = useState(false);
  const [isButtonzMode, setIsButtonzMode] = useState(false);
  const [isMatchingMode, setIsMatchingMode] = useState(false);
  const [isCubeyMode, setIsCubeyMode] = useState(false);
  const [userProgress, setUserProgress] = useState<Record<string, number>>({});
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadUserProgress = async () => {
      if (!user) return;
      
      try {
        const { data: progressData, error: progressError } = await supabase
          .from('user_progress')
          .select('*')
          .eq('user_id', user.id);

        if (progressError) throw progressError;

        const progressMap = progressData.reduce((acc, curr) => ({
          ...acc,
          [curr.flashcard_id]: curr.ease_factor
        }), {});

        setUserProgress(progressMap);
      } catch (err) {
        console.error('Error loading user progress:', err);
      }
    };

    loadUserProgress();
  }, [user]);

  useEffect(() => {
    let mounted = true;
    let setsSubscription: any;
    let cardsSubscription: any;
    let progressSubscription: any;

    const fetchData = async () => {
      if (!user || !mounted) return;
      
      try {
        setDataLoading(true);
        setError(null);

        const { data: setsData, error: setsError } = await supabase
          .from('flashcard_sets')
          .select('*')
          .eq('created_by', user.id);

        if (setsError) throw setsError;
        if (!mounted) return;

        const { data: cardsData, error: cardsError } = await supabase
          .from('flashcards')
          .select('*')
          .in('set_id', setsData.map(set => set.id));

        if (cardsError) throw cardsError;
        if (!mounted) return;

        const { data: progressData, error: progressError } = await supabase
          .from('user_progress')
          .select('*')
          .eq('user_id', user.id);

        if (progressError) throw progressError;

        const progressMap = progressData?.reduce((acc, progress) => ({
          ...acc,
          [progress.flashcard_id]: {
            easeFactor: progress.ease_factor,
            repetitions: progress.repetitions,
            lastReview: progress.last_review,
            nextReview: progress.next_review
          }
        }), {}) || {};

        const transformedSets = setsData.map(set => ({
          id: set.id,
          name: set.name,
          createdAt: new Date(set.created_at),
          masteredCards: cardsData.filter(card =>
            progressMap[card.id]?.easeFactor > 2.5
          ).length,
          totalCards: cardsData.filter(card => card.set_id === set.id).length,
          isPublic: set.is_public
        }));

        const transformedCards = cardsData.map(card => ({
          id: card.id,
          setId: card.set_id,
          word: card.word,
          definition: card.definition,
          easeFactor: progressMap[card.id]?.easeFactor || 2.0,
          repetitions: progressMap[card.id]?.repetitions || 0,
          nextReview: progressMap[card.id]?.nextReview ? new Date(progressMap[card.id].nextReview) : new Date(),
          lastReview: progressMap[card.id]?.lastReview ? new Date(progressMap[card.id].lastReview) : null
        }));

        if (mounted) {
          setSets(transformedSets);
          setCards(transformedCards);
          setError(null);
          setDataLoading(false);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        if (mounted) {
          setError('Failed to load flashcards');
        }
      }
    };

    if (user) {
      fetchData();

      setsSubscription = supabase
        .channel('sets_changes')
        .on('postgres_changes', { 
          event: '*', 
          schema: 'public', 
          table: 'flashcard_sets',
          filter: `created_by=eq.${user.id}`
        }, () => {
          if (mounted) fetchData();
        })
        .subscribe();

      cardsSubscription = supabase
        .channel('cards_changes')
        .on('postgres_changes', { 
          event: '*', 
          schema: 'public', 
          table: 'flashcards' 
        }, () => {
          if (mounted) fetchData();
        })
        .subscribe();
    } else {
      setSets([]);
      setCards([]);
      setDataLoading(false);
    }

    return () => {
      mounted = false;
      if (setsSubscription) setsSubscription.unsubscribe();
      if (cardsSubscription) cardsSubscription.unsubscribe();
    };
  }, [user]);

  useEffect(() => {
    if (currentSet && cards.length > 0) {
      const setCards = cards.filter(card => card.setId === currentSet.id);
      if (setCards.length > 0) {
        setCurrentCard(setCards[0]);
      }
    }
  }, [currentSet, cards]);

  const handleSetSelect = (setId: string) => {
    const selectedSet = sets.find(set => set.id === setId);
    if (selectedSet) {
      setCurrentSet(selectedSet);
      setIsReviewing(true);
      
      const setCards = cards.filter(card => card.setId === setId);
      if (setCards.length > 0) {
        setCurrentCard(setCards[0]);
      }
    }
  };

  const handleCardResponse = (quality: number) => {
    if (!currentSet || !currentCard) return;

    const setCards = cards.filter(card => card.setId === currentSet.id);
    const currentIndex = setCards.findIndex(card => card.id === currentCard.id);
    
    const nextIndex = (currentIndex + 1) % setCards.length;
    setCurrentCard(setCards[nextIndex]);
    setIsFlipped(false);
  };

  if (authLoading) {
    return (
      <div className="min-h-screen bg-primary flex items-center justify-center">
        <div className="text-center">
          <p className="text-2xl text-white mb-4">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <Router>
    <div className="min-h-screen bg-primary">
      <Routes>
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route
          path="/"
          element={
            isGameMode ? (
              <VocabularyGame 
                cards={cards} 
                currentSet={currentSet}
                onExit={() => setIsGameMode(false)} 
              />
            ) : isButtonzMode ? (
              <ButtonzGame
                cards={cards}
                currentSet={currentSet}
                onExit={() => setIsButtonzMode(false)}
              />
            ) : isMatchingMode ? (
              <MatchingGame
                cards={cards}
                currentSet={currentSet}
                onExit={() => setIsMatchingMode(false)}
              />
            ) : isCubeyMode ? (
              <CubeyGame
                onExit={() => setIsCubeyMode(false)}
              />
            ) : isReviewing ? (
              <div className="container mx-auto px-4 py-12">
                <div className="flex items-center justify-between">
                  <div className="flex flex-wrap items-center gap-2 md:gap-4">
                    <button
                      onClick={() => {
                        setIsReviewing(false);
                        setCurrentCard(null);
                        setCurrentSet(null);
                      }}
                      className="button-3d text-sm md:text-base px-3 py-1 md:px-8 md:py-2"
                    >
                      ← Back
                    </button>
                    <button
                      onClick={() => setIsGameMode(true)}
                      className="button-3d text-sm md:text-base px-3 py-1 md:px-8 md:py-2"
                    >
                      Block Buster
                    </button>
                    <button
                      onClick={() => setIsMatchingMode(true)}
                      className="button-3d text-sm md:text-base px-3 py-1 md:px-8 md:py-2"
                    >
                      Match Em
                    </button>
                    <button
                      onClick={() => setIsButtonzMode(true)}
                      className="button-3d text-sm md:text-base px-3 py-1 md:px-8 md:py-2"
                    >
                      Buttenz
                    </button>
                    <button
                      onClick={() => setIsCubeyMode(true)}
                      className="button-3d text-sm md:text-base px-3 py-1 md:px-8 md:py-2"
                    >
                      Cubey
                    </button>
                  </div>
                  <UserMenu />
                </div>
                
                <div className="mt-8">
                  {cards.length > 0 && <Stats cards={cards.filter(card => currentSet && card.setId === currentSet.id)} />}
                </div>

                <div className="mt-8">
                  {currentCard ? (
                    <FlashCardView
                      card={currentCard}
                      cards={cards}
                      currentSet={currentSet}
                      isFlipped={isFlipped}
                      onFlip={() => setIsFlipped(!isFlipped)}
                      onResponse={handleCardResponse}
                    />
                  ) : (
                    <div className="text-center">
                      <p className="text-2xl text-white mb-4">No cards available</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <SetsPage 
                sets={sets} 
                onSetSelect={handleSetSelect}
                onCardsUpload={async (newCards, fileName) => {
                  if (!user) {
                    alert('Please sign in to add flashcard sets');
                    return;
                  }
                }}
                onStartQuiz={() => {
                  setIsQuizMode(true);
                  setIsReviewing(true);
                }}
              />
            )
          }
        />
      </Routes>
    </div>
    </Router>
  );
}

export default App;