import React, { useState, useEffect, useRef } from 'react';
import { FlashCard, FlashCardSet } from '../types';
import { ArrowLeft, RotateCcw } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Header from './Header';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { playSound, initAudio } from '../lib/audio';

interface MatchingGameProps {
  cards: FlashCard[];
  currentSet: FlashCardSet | null;
  onExit: () => void;
}

interface CardProps {
  word: string;
  definition: string;
  isFlipped: boolean;
  isCorrect: boolean;
  onClick: () => void;
}

function Card({ word, definition, isFlipped, isCorrect, onClick }: CardProps) {
  return (
    <AnimatePresence>
      {!isCorrect && (
        <motion.div
          initial={{ scale: 1, opacity: 1 }}
          exit={{ 
            scale: [1, 1.2, 0],
            opacity: [1, 1, 0],
            rotate: [0, 15, -15, 0]
          }}
          transition={{ duration: 0.5 }}
          className="w-40 h-40 cursor-pointer perspective-1000"
          onClick={onClick}
        >
          <div 
            className={`relative w-full h-full transition-transform duration-500 transform-style-3d ${
              isFlipped ? 'rotate-y-180' : ''
            }`}
          >
            <div 
              className={`absolute w-full h-full rounded-md shadow-[10px_10px_0px_0px_rgba(0,0,0,1)] bg-[#FF1C60] text-white flex items-center justify-center p-4 text-center backface-hidden`}
            >
              <span className="text-2xl font-bold">{word}</span>
            </div>
            
            <div 
              className={`absolute w-full h-full rounded-md shadow-[10px_10px_0px_0px_rgba(0,0,0,1)] bg-[#FF1C60] text-white flex items-center justify-center p-4 text-center backface-hidden rotate-y-180`}
            >
              <span className="text-xl font-bold">{definition}</span>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default function MatchingGame({ cards, currentSet, onExit }: MatchingGameProps) {
  const { user } = useAuth();
  const [gameStartTime] = useState(Date.now());
  const [gameCards, setGameCards] = useState<FlashCard[]>([]);
  const [availableCards, setAvailableCards] = useState<FlashCard[]>([]);
  const [flippedIndices, setFlippedIndices] = useState<number[]>([]);
  const [correctIndices, setCorrectIndices] = useState<number[]>([]);
  const [score, setScore] = useState(0);
  const [currentDefinition, setCurrentDefinition] = useState<{ content: string; cardIndex: number } | null>(null);
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    initAudio();
  }, []);

  useEffect(() => {
    if (currentSet) {
      startNewGame();
    }
  }, [cards, currentSet]);

  const startNewGame = () => {
    const setCards = cards.filter(card => card.setId === currentSet?.id);
    const shuffledCards = shuffleArray([...setCards]);
    const initialGameCards = shuffledCards.slice(0, 6);
    setGameCards(initialGameCards);
    setAvailableCards(shuffledCards.slice(6));
    setFlippedIndices([]);
    setCorrectIndices([]);
    setScore(0);
    setGameOver(false);
    pickNewDefinition(initialGameCards, []);
  };

  const shuffleArray = <T,>(array: T[]): T[] => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const pickNewDefinition = (currentCards: FlashCard[], matched: number[]) => {
    // Only pick from cards that are currently visible and not matched
    const availableIndices = currentCards.map((_, index) => index)
      .filter(index => !matched.includes(index));
    
    if (availableIndices.length === 0) {
      if (currentCards.length === 0 && availableCards.length === 0) {
        setGameOver(true);
      }
      return;
    }

    const randomIndex = availableIndices[Math.floor(Math.random() * availableIndices.length)];
    const selectedCard = currentCards[randomIndex];
    
    setCurrentDefinition({
      content: selectedCard.definition,
      cardIndex: randomIndex
    });
  };

  const handleCardClick = async (index: number) => {
    if (flippedIndices.includes(index) || correctIndices.includes(index) || !currentDefinition) return;

    setFlippedIndices(prev => [...prev, index]);

    const duration = Math.floor((Date.now() - gameStartTime) / 1000);

    if (index === currentDefinition.cardIndex) {
      // Play success sound
      playSound('success');

      setScore(prevScore => prevScore + 1);
      setCorrectIndices(prev => [...prev, index]);

      // Save score and update time_spent
      if (user) {
        try {
          // First, save the game score
          const { error: insertError } = await supabase.from('game_scores').insert({
            user_id: user.id,
            game_type: 'matching',
            score: 1,
            duration: duration,
          });

          if (insertError) throw insertError;

          // Fetch the current time_spent
          const { data: profile, error: fetchError } = await supabase
            .from('profiles')
            .select('time_spent')
            .eq('id', user.id)
            .single();

          if (fetchError) throw fetchError;

          const newTimeSpent = (profile?.time_spent || 0) + duration;

          // Update the time_spent
          const { error: updateError } = await supabase
            .from('profiles')
            .update({ time_spent: newTimeSpent })
            .eq('id', user.id);

          if (updateError) throw updateError;
        } catch (err) {
          console.error('Error saving game score or updating time spent:', err);
        }
      }

      // Add new card if available
      setTimeout(() => {
        let newGameCards: FlashCard[];
        if (availableCards.length > 0) {
          const [newCard, ...remaining] = availableCards;
          newGameCards = gameCards.filter((_, i) => i !== index).concat(newCard);
          setGameCards(newGameCards);
          setAvailableCards(remaining);
        } else {
          newGameCards = gameCards.filter((_, i) => i !== index);
          setGameCards(newGameCards);
        }

        setFlippedIndices([]);
        setCorrectIndices([]);
        pickNewDefinition(newGameCards, []);
      }, 500);
    } else {
      // Play mistake sound
      playSound('mistake');

      setScore(prevScore => prevScore - 1);

      // Save negative score and update time_spent
      if (user) {
        try {
          // First, save the game score
          const { error: insertError } = await supabase.from('game_scores').insert({
            user_id: user.id,
            game_type: 'matching',
            score: -1,
            duration: duration,
          });

          if (insertError) throw insertError;

          // Fetch the current time_spent
          const { data: profile, error: fetchError } = await supabase
            .from('profiles')
            .select('time_spent')
            .eq('id', user.id)
            .single();

          if (fetchError) throw fetchError;

          const newTimeSpent = (profile?.time_spent || 0) + duration;

          // Update the time_spent
          const { error: updateError } = await supabase
            .from('profiles')
            .update({ time_spent: newTimeSpent })
            .eq('id', user.id);

          if (updateError) throw updateError;
        } catch (err) {
          console.error('Error saving game score or updating time spent:', err);
        }
      }

      setTimeout(() => {
        setFlippedIndices(prev => prev.filter(i => i !== index));
      }, 1000);
    }
  };

  return (
    <div className="min-h-screen bg-primary p-4">
      <div className="max-w-6xl mx-auto space-y-8">
        <Header
          showBackButton
          onBack={onExit}
          score={score}
        />

        {currentDefinition && !gameOver && (
          <div className="text-center mb-12">
            <p className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-4">
              {currentDefinition.content}
            </p>
            <p className="text-white/80 text-sm">
              Match this definition with its word below
            </p>
          </div>
        )}

        <div className="grid grid-cols-2 md:grid-cols-3 gap-8 justify-items-center">
          {gameCards.map((card, index) => (
            <Card
              key={`${card.id}-${index}`}
              word={card.word}
              definition={card.definition}
              isFlipped={flippedIndices.includes(index)}
              isCorrect={correctIndices.includes(index)}
              onClick={() => handleCardClick(index)}
            />
          ))}
        </div>

        <AnimatePresence>
          {gameOver && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                transition={{ type: "spring", duration: 0.5 }}
                className="bg-accent p-8 rounded-[2px] shadow-brutal text-center relative overflow-hidden"
              >
                <span className="text-6xl mb-4 block">🏆</span>
                <h3 className="text-3xl font-bold text-white mb-4">Game Over!</h3>
                <p className={`text-xl text-white mb-6 ${score < 0 ? 'text-accent' : 'text-highlight'}`}>
                  Final Score: {score}
                </p>
                <div className="space-x-4">
                  <button 
                    onClick={startNewGame}
                    className="button-3d flex items-center gap-2 inline-flex"
                  >
                    <RotateCcw className="w-5 h-5" />
                    Play Again
                  </button>
                  <button 
                    onClick={onExit}
                    className="button-3d flex items-center gap-2 inline-flex"
                  >
                    <ArrowLeft className="w-5 h-5" />
                    Exit
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}