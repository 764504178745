import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useNavigate } from 'react-router-dom';
import { Users, BookOpen, Trophy, Clock, LogOut, Search } from 'lucide-react';

interface DashboardStats {
  totalUsers: number;
  totalSets: number;
  totalCards: number;
  activeUsers24h: number;
}

interface UserData {
  id: string;
  email: string;
  screenname: string | null;
  avatar_url: string | null;
  last_seen: string | null;
}

export default function AdminDashboard() {
  const [stats, setStats] = useState<DashboardStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<UserData[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const isAdmin = localStorage.getItem('admin_session') === 'true';
      if (!isAdmin) {
        navigate('/admin');
        return;
      }

      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user) {
        navigate('/admin');
        return;
      }

      const { data: profile } = await supabase
        .from('profiles')
        .select('is_admin')
        .eq('id', session.user.id)
        .single();

      if (!profile?.is_admin) {
        navigate('/admin');
        return;
      }
    };

    checkAuth();
  }, [navigate]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const [
          { count: userCount },
          { count: setCount },
          { count: cardCount },
          { count: activeCount }
        ] = await Promise.all([
          supabase.from('profiles').select('*', { count: 'exact' }),
          supabase.from('flashcard_sets').select('*', { count: 'exact' }),
          supabase.from('flashcards').select('*', { count: 'exact' }),
          supabase.from('profiles')
            .select('*', { count: 'exact' })
            .gte('last_activity', new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString())
        ]);

        setStats({
          totalUsers: userCount || 0,
          totalSets: setCount || 0,
          totalCards: cardCount || 0,
          activeUsers24h: activeCount || 0
        });
      } catch (err) {
        console.error('Error fetching stats:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // First get auth users with emails
        const { data: authUsers } = await supabase.auth.admin.listUsers();
        
        if (!authUsers?.users) return;

        // Then get profiles
        const { data: profiles } = await supabase
          .from('profiles')
          .select('id, screenname, avatar_url, last_seen');

        // Create a map of profiles by ID
        const profileMap = new Map(profiles?.map(p => [p.id, p]) || []);

        // Combine auth users with their profiles
        const combinedUsers = authUsers.users
          .map(user => ({
            id: user.id,
            email: user.email || '',
            screenname: profileMap.get(user.id)?.screenname || null,
            avatar_url: profileMap.get(user.id)?.avatar_url || null,
            last_seen: profileMap.get(user.id)?.last_seen || null
          }))
          .sort((a, b) => {
            // Sort by last_seen descending, nulls last
            if (!a.last_seen) return 1;
            if (!b.last_seen) return -1;
            return new Date(b.last_seen).getTime() - new Date(a.last_seen).getTime();
          })
          .slice(0, 20) // Get last 20 active users
          .filter(user => 
            user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.screenname?.toLowerCase() || '').includes(searchQuery.toLowerCase())
          );


        setUsers(combinedUsers);
      } catch (err) {
        console.error('Error fetching users:', err);
      }
    };

    fetchUsers();
  }, [searchQuery]);

  const handleLogout = () => {
    localStorage.removeItem('admin_session');
    navigate('/admin');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-primary flex items-center justify-center">
        <p className="text-white">Loading stats...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-primary p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-12">
          <h1 className="text-4xl font-bold text-white">Admin Dashboard</h1>
          <button
            onClick={handleLogout}
            className="button-3d flex items-center gap-2"
          >
            <LogOut className="w-5 h-5" />
            Logout
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="bg-accent p-6 rounded-[2px] shadow-brutal">
            <div className="flex items-center gap-4 mb-4">
              <div className="w-12 h-12 bg-highlight rounded-full flex items-center justify-center">
                <Users className="w-6 h-6" />
              </div>
              <h2 className="text-xl font-bold text-white">Total Users</h2>
            </div>
            <p className="text-4xl font-bold text-highlight">{stats?.totalUsers}</p>
          </div>

          <div className="bg-accent p-6 rounded-[2px] shadow-brutal">
            <div className="flex items-center gap-4 mb-4">
              <div className="w-12 h-12 bg-highlight rounded-full flex items-center justify-center">
                <BookOpen className="w-6 h-6" />
              </div>
              <h2 className="text-xl font-bold text-white">Flash Card Sets</h2>
            </div>
            <p className="text-4xl font-bold text-highlight">{stats?.totalSets}</p>
          </div>

          <div className="bg-accent p-6 rounded-[2px] shadow-brutal">
            <div className="flex items-center gap-4 mb-4">
              <div className="w-12 h-12 bg-highlight rounded-full flex items-center justify-center">
                <Trophy className="w-6 h-6" />
              </div>
              <h2 className="text-xl font-bold text-white">Total Cards</h2>
            </div>
            <p className="text-4xl font-bold text-highlight">{stats?.totalCards}</p>
          </div>

          <div className="bg-accent p-6 rounded-[2px] shadow-brutal">
            <div className="flex items-center gap-4 mb-4">
              <div className="w-12 h-12 bg-highlight rounded-full flex items-center justify-center">
                <Clock className="w-6 h-6" />
              </div>
              <h2 className="text-xl font-bold text-white">Active Users (24h)</h2>
            </div>
            <p className="text-4xl font-bold text-highlight">{stats?.activeUsers24h}</p>
          </div>
        </div>

        <div className="mt-12">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-white">Users</h2>
            <div className="relative w-64">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-black/50" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search users..."
                className="w-full pl-10 pr-4 py-2 rounded-[2px] bg-white text-black"
              />
            </div>
          </div>

          <div className="bg-[#FF1C60] rounded-[2px] shadow-brutal overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-highlight">
                    <th className="text-left p-4">User</th>
                    <th className="text-left p-4">Email</th>
                    <th className="text-left p-4">Last Seen</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.id} className="border-t border-black/10">
                      <td className="p-4">
                        <div className="flex items-center gap-3">
                          {user.avatar_url ? (
                            <img
                              src={user.avatar_url}
                              alt={user.screenname || 'User'}
                              className="w-10 h-10 rounded-full object-cover"
                              crossOrigin="anonymous"
                            />
                          ) : (
                            <div className="w-10 h-10 rounded-full bg-white/20 flex items-center justify-center">
                              <span className="text-white/60 text-xl">
                                {(user.screenname || user.email[0]).toUpperCase()}
                              </span>
                            </div>
                          )}
                          <span className="text-white font-medium">
                            {user.screenname || 'Anonymous'}
                          </span>
                        </div>
                      </td>
                      <td className="p-4 text-white/80">{user.email}</td>
                      <td className="p-4 text-white/80">
                        {user.last_seen 
                          ? new Date(user.last_seen).toLocaleString()
                          : 'Never'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}