import React, { useState, useRef, useEffect } from 'react';
import { FlashCard } from '../types';
import { ThumbsUp, ThumbsDown } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import ButtonzGame from './ButtonzGame';

interface FlashCardViewProps {
  card: FlashCard;
  cards: FlashCard[];
  currentSet: FlashCardSet | null;
  isFlipped: boolean;
  onFlip: () => void;
  onResponse: (quality: 0 | 1 | 2 | 3 | 4 | 5) => void;
}

export default function FlashCardView({ card, cards, currentSet, isFlipped, onFlip, onResponse }: FlashCardViewProps) {
  const { user } = useAuth();
  const cardRef = useRef<HTMLDivElement>(null);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const velocityRef = useRef({ x: 0, y: 0 });
  const lastPositionRef = useRef({ x: 0, y: 0 });
  const lastTimeRef = useRef(0);
  const animationFrameRef = useRef<number>();
  const touchStartTimeRef = useRef(0);


  useEffect(() => {
    setDragOffset({ x: 0, y: 0 });
    setIsAnimating(false);
    velocityRef.current = { x: 0, y: 0 };
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
  }, [card]);

  const updateProgress = async (quality: number) => {
    if (!user || !card) return;

    // Calculate new ease factor based on response quality
    const newEaseFactor = quality >= 4 ? 3.0 : 2.0;
    const nextReview = new Date();
    nextReview.setDate(nextReview.getDate() + (quality >= 4 ? 3 : 1));

    try {
      const { error } = await supabase
        .from('user_progress')
        .upsert({
          user_id: user.id,
          flashcard_id: card.id,
          ease_factor: newEaseFactor,
          repetitions: (card.repetitions || 0) + 1,
          last_review: new Date().toISOString(),
          next_review: nextReview.toISOString()
        }, {
          onConflict: 'user_id,flashcard_id'
        });

      if (error) throw error;

      // Update local card state
      card.easeFactor = newEaseFactor;
      card.repetitions = (card.repetitions || 0) + 1;
      card.nextReview = nextReview;
      card.lastReview = new Date();
    } catch (err) {
      console.error('Error updating progress:', err);
    }
  };

  const handleDragStart = (clientX: number, clientY: number) => {
    if (isAnimating) return;
    setIsDragging(true);
    setDragStart({ x: clientX, y: clientY });
    lastPositionRef.current = { x: clientX, y: clientY };
    lastTimeRef.current = performance.now();
    touchStartTimeRef.current = Date.now();
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
  };

  const handleDragMove = (clientX: number, clientY: number) => {
    if (!isDragging || isAnimating) return;

    const now = performance.now();
    const deltaTime = now - lastTimeRef.current;

    if (deltaTime > 0) {
      velocityRef.current = {
        x: (clientX - lastPositionRef.current.x) / deltaTime * 0.8,
        y: (clientY - lastPositionRef.current.y) / deltaTime * 0.8
      };
    }

    lastPositionRef.current = { x: clientX, y: clientY };
    lastTimeRef.current = now;

    const deltaX = clientX - dragStart.x;
    const deltaY = clientY - dragStart.y;

    // Add resistance to the drag
    const resistance = 0.7;
    setDragOffset({
      x: deltaX * resistance,
      y: deltaY * resistance
    });
  };

  const animateSwipe = (direction: number) => {
    setIsAnimating(true);
    const startX = dragOffset.x;
    const targetX = direction * window.innerWidth * 1.5;
    const startTime = performance.now();
    const duration = 400;

    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      const easeOut = 1 - Math.pow(1 - progress, 4);

      const currentX = startX + (targetX - startX) * easeOut;
      setDragOffset(prev => ({ ...prev, x: currentX }));

      if (progress < 1) {
        animationFrameRef.current = requestAnimationFrame(animate);
      } else {
        setIsAnimating(false);
        const quality = direction > 0 ? 4 : 1;
        updateProgress(quality);
        setTimeout(() => onResponse(quality), 100); // Small delay to ensure progress is saved
      }
    };

    animationFrameRef.current = requestAnimationFrame(animate);
  };

  const handleDragEnd = () => {
    if (!isDragging || isAnimating) return;
    setIsDragging(false);

    const threshold = window.innerWidth * 0.3;
    const velocity = velocityRef.current.x;
    const velocityThreshold = 0.5;
    const swipeDuration = Date.now() - touchStartTimeRef.current;
    const isQuickSwipe = swipeDuration < 300;

    if (Math.abs(dragOffset.x) > threshold || Math.abs(velocity) > velocityThreshold || (isQuickSwipe && Math.abs(dragOffset.x) > 50)) {
      const direction = dragOffset.x > 0 ? 1 : -1;
      // Update card's ease factor when swiping right
      if (direction > 0) {
        card.easeFactor = 3.0; // Set higher than 2.5 to mark as mastered
      } else {
        card.easeFactor = 2.0;
      }
      animateSwipe(direction);
    } else {
      const startX = dragOffset.x;
      const startTime = performance.now();
      const duration = 350;

      const animate = (currentTime: number) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        const spring = 1 - Math.pow(1 - progress, 4) * Math.cos(progress * Math.PI * 1.5);

        const currentX = startX * (1 - spring);
        setDragOffset(prev => ({ ...prev, x: currentX }));

        if (progress < 1) {
          animationFrameRef.current = requestAnimationFrame(animate);
        }
      };

      animationFrameRef.current = requestAnimationFrame(animate);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => handleDragStart(e.clientX, e.clientY);
  const handleMouseMove = (e: React.MouseEvent) => handleDragMove(e.clientX, e.clientY);
  const handleMouseUp = () => handleDragEnd();
  const handleTouchStart = (e: React.TouchEvent) => handleDragStart(e.touches[0].clientX, e.touches[0].clientY);
  const handleTouchMove = (e: React.TouchEvent) => {
    e.preventDefault();
    handleDragMove(e.touches[0].clientX, e.touches[0].clientY);
  };
  const handleTouchEnd = () => handleDragEnd();

  const rotation = (dragOffset.x / window.innerWidth) * 25;
  const opacity = Math.max(0, 1 - Math.abs(dragOffset.x) / (window.innerWidth * 0.5));
  const rightIndicatorOpacity = Math.min(1, Math.max(0, dragOffset.x / (window.innerWidth * 0.3)));
  const leftIndicatorOpacity = Math.min(1, Math.max(0, -dragOffset.x / (window.innerWidth * 0.3)));

  return (
    <div className="w-full h-full flex flex-col gap-8">
      <div className="flex-1 flex flex-col justify-center items-center">
      
      {/* Flash Card Area */}
      <div className="w-full max-w-xl relative">
        {/* Indicators */}
        <div className="absolute inset-0 pointer-events-none flex items-center justify-between px-8 z-10">
          <div style={{ opacity: leftIndicatorOpacity }} className="flex items-center text-accent transition-opacity duration-200 scale-75 md:scale-100">
            <ThumbsDown className="w-8 h-8 md:w-12 md:h-12" />
            <span className="ml-2 text-xl md:text-2xl font-bold">Don't Know</span>
          </div>
          <div style={{ opacity: rightIndicatorOpacity }} className="flex items-center text-highlight transition-opacity duration-200 scale-75 md:scale-100">
            <span className="mr-2 text-xl md:text-2xl font-bold">Know It</span>
            <ThumbsUp className="w-8 h-8 md:w-12 md:h-12" />
          </div>
        </div>
      </div>

        {/* Draggable Card */}
        <div 
          ref={cardRef}
          className="relative aspect-[3/4] w-full max-w-[35%] mx-auto perspective-1000 cursor-grab active:cursor-grabbing touch-none"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className="absolute w-full h-full transition-transform duration-300 transform-style-3d will-change-transform"
            style={{
              transform: `${isFlipped ? 'rotateY(180deg)' : ''} translateX(${dragOffset.x}px) rotate(${rotation}deg)`,
              opacity,
              transition: isDragging ? 'none' : 'all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)',
              transformStyle: 'preserve-3d'
            }}
            onClick={(e) => {
              if (!isDragging) onFlip();
              e.stopPropagation();
            }}
          >
            {/* Front Side */}
            <div 
              className="absolute w-full h-full bg-accent rounded-[2px] p-8 flex flex-col items-center"
              style={{ 
                boxShadow: '10px 10px 0 rgba(0, 0, 0, 1)',
                backfaceVisibility: 'hidden'
              }}
            >
              <div className="flex-1 flex items-center justify-center">
                <h2 className="text-3xl font-bold text-white">{card.word}</h2>
              </div>
              <p className="text-white/80 text-sm">Tap to flip, swipe to rate</p>
            </div>

            {/* Back Side */}
            <div 
              className="absolute w-full h-full bg-accent rounded-[2px] p-8 flex flex-col items-center justify-center"
              style={{ 
                boxShadow: '10px 10px 0 rgba(0, 0, 0, 1)',
                backfaceVisibility: 'hidden',
                transform: 'rotateY(180deg)'
              }}
            >
              <p className="text-lg text-white text-center">{card.definition}</p>
            </div>
          </div>
        </div>

        {/* Instructions */}
        <div className="mt-4 text-center text-white">
          <p className="text-base">Swipe right if you know it, left if you don't</p>
          <p className="text-sm mt-2 opacity-80">Or tap the card to see the definition</p>
        </div>
      </div>
    </div>
  );
}